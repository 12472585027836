import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import Header from './Header';
import axios from 'axios';

export default function ShowEstimateEvent() {
    const [estimateData, setEstimateData] = useState([]);
    const [estimateChildData, setEstimateChildData] = useState([]);
    const [estimateChildData1, setEstimateChildData1] = useState([]);
    const [previousData, setPreviousData] = useState(0);
    const [pax, setPax] = useState(0);
    const [paxData, setPaxData] = useState(0);
    const [estimateCommonData, setEstimateCommonData] = useState([]);
    const [estimateCommonData1, setEstimateCommonData1] = useState([]);
    const [estimateCommonData2, setEstimateCommonData2] = useState({});
    const [fabriconStages, setFabriconStages] = useState([]);
    const [fabriconPlatforms, setFabriconPlatforms] = useState([]);
    const [selectedEstimate, setSelectedEstimate] = useState('');
    const [subChildEstimate, setSubChildEstimate] = useState({
        'fabrications': [],
        'fabrications_st_plt': [],
        'fabrications_plt': [],
        'fabrication': [],
        'Sound': [],
        'Video': [],
        'Lights': [],
        'Genset': [],
        'Permission': [],
        'Manpower': [],
        'Addons': []
    });
    const [fabrication, setFabrication] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [selectedText, setSelectedText] = useState("");
    const [selectedText1, setSelectedText1] = useState("");
    const [selectAll, setSelectAll] = useState(
        {

        }
    );
    const navigate = useNavigate();
    const { eventId } = useParams();
    const location = useLocation();
    const role = location.state?.role || '';
    const pmId = location.state?.pmId || '';
    const clientId = location.state?.clientId || sessionStorage.getItem('clientId');
    const compId = location.state?.compId || '';
    const pmName = location.state?.pmName || '';
    const companyName = location.state?.companyName || '';

    useEffect(() => {
        setPreviousData(location.state?.previousData);
        setSelectedText(location.state?.setup || '')
        // when back button click then selected previous value should be comes as per robiee requirement 
        setSelectedText1(location.state?.estimate_parent || '');
        setSelectedEstimate(location.state?.selectedEstimate || '');
        setEstimateData(location.state?.estimateData || '')
        setPaxData(location.state?.paxData || '');
        setEstimateChildData(location.state?.estimateChildData || []);
        setEstimateChildData1(location.state?.estimateChildData1 || []);
        setFabrication(location.state?.fabrication || []);
        setEstimateCommonData(location.state?.estimateCommonData || []);
        setEstimateCommonData1(location.state?.estimateCommonData1 || []);
        setEstimateCommonData2(location.state?.estimateCommonData2 || {});
        setFabriconStages(location.state?.fabriconStages || []);
        setFabriconPlatforms(location.state?.fabriconPlatforms || []);
        setPax(location.state?.pax || 0);
        setSubChildEstimate(location.state?.subChildEstimate || {
            'fabrications': [],
            'fabrications_st_plt': [],
            'fabrications_plt': [],
            'fabrication': [],
            'Sound': [],
            'Video': [],
            'Lights': [],
            'Genset': [],
            'Permission': [],
            'Manpower': [],
            'Addons': []
        });
    }, [location.state]); // Dependency array
    const fetchEstimateData = async (estimateValue) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/${estimateValue}`);
            setEstimateData(response.data || []);
        } catch (err) {
            console.error(err);
            setEstimateData([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchEstimateChildData = async (paxValue) => {
        setLoading1(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/dropdown_values_for_both`);
            setEstimateChildData(response.data || []);
        } catch (err) {
            console.error(err);
            setEstimateChildData([]);
        } finally {
            setLoading1(false);
        }
    };
    // Redirect to Estimate exhibition page when client click on exhibition button
    const handleExhibition = (e) => {
        e.preventDefault()
        console.log("handle = ", clientId);
        navigate(`/dashboard/showexhibitionevent/${eventId}`,
            {
                state: {
                    clientId: clientId,
                    role: role,
                    pmId: pmId,
                    compId: compId,
                    pmName: pmName,
                    companyName: companyName,
                }
            });
    }
    // Redirect to Estimate Iclinic page when client click on ICLINIC SOLUTION button
    const handleIclinic = (e) => {
        e.preventDefault();
        navigate(`/dashboard/showiclinicevent/${eventId}`,
            {
                state: {
                    clientId: clientId,
                    role: role,
                    pmId: pmId,
                    compId: compId,
                    pmName: pmName,
                    companyName: companyName,
                }
            }
        );
    }
    const fetchEstimateSubChildData = async (childEstimateVal) => {
        console.log(childEstimateVal);
        setLoading2(true);
        try {
            const arr = ['1000', '150', '200', '250', '500'];
            const params = { pax };
            let fabricationData = [];
            let stagesData = [];
            let platformsData = [];
            let allEstimateData = [];
            const CategoryData = [];
            const CategoryData1 = [];
            for (const val of childEstimateVal) {
                let childEstimateUrl = '';
                if (val === 'Fabrication') {  // if estimate val is fabrication
                    childEstimateUrl = 'fabrication_stages';
                    if (arr.includes(pax)) { // for projector and led setup, if estimate val fabrication and pax value lie between [100,150,200,250,500]
                        const response = await axios.get(`${process.env.REACT_APP_API_URL}/fabrications`, { params });
                        if (response.data.length > 0) {
                            response.data.map((item, index) => (
                                fabricationData.push(response.data[index])
                            ))
                        }
                    } else {   // for projector and led setup, if estimate val fabrication and pax value lie between [50,100]
                        const [stagesResponse, platformsResponse] = await Promise.all([
                            axios.get(`${process.env.REACT_APP_API_URL}/${childEstimateUrl}`, { params }),
                            axios.get(`${process.env.REACT_APP_API_URL}/fabrication_platforms`, { params })
                        ]);
                        if (stagesResponse.data.length > 0) {
                            stagesResponse.data.map((item, index) => (
                                stagesData.push(stagesResponse.data[index])
                            ))
                        }
                        if (platformsResponse.data.length > 0) {
                            platformsResponse.data.map((item, index) => (
                                platformsData.push(platformsResponse.data[index])
                            ))
                        }
                    }
                } else {
                    // if estimate val not fabrication and pax value any
                    childEstimateUrl = val;
                    // Fetch data for other estimates
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/${childEstimateUrl}`, { params });
                    if (response.data.length > 0) {
                        allEstimateData = response.data;
                        CategoryData.push({ category: val, items: allEstimateData });

                    }
                }
            }
            // Update state
            setFabrication(fabricationData);
            setFabriconStages(stagesData);
            setFabriconPlatforms(platformsData);
            setEstimateCommonData(CategoryData);
            if (selectAll.length > 1) {
                setEstimateCommonData1([]);
            }
            // setEstimateCommonData2({});
            console.log("Fabrication data =", fabricationData);
            console.log("Stages data =", stagesData);
            console.log("Platforms data =", platformsData);
            console.log("allEstimateData  =", CategoryData);

        } catch (err) {
            console.error(err);
            setFabriconStages([]);
            setFabriconPlatforms([]);
            setFabrication([]);
            setEstimateCommonData([]);
        } finally {
            setLoading2(false);
        }
    };

    const handleChange = async (e) => {
        const estimateValue = e.target.value;
        setSelectedEstimate(estimateValue);
        if (estimateValue.length > 0) {
            if (estimateValue == 'projector_setups') {
                setSelectedText('Projector Setup');
                setFabriconStages([]);
                setFabriconPlatforms([]);
                setFabrication([]);
                setEstimateChildData1([]);
                setSubChildEstimate()
                setSubChildEstimate({
                    'fabrications': [],
                    'fabrications_st_plt': [],
                    'fabrications_plt': [],
                    'fabrication': [],
                    'Sound': [],
                    'Video': [],
                    'Lights': [],
                    'Genset': [],
                    'Permission': [],
                    'Manpower': [],
                    'Addons': []
                });
                setEstimateCommonData1([]);
                setEstimateCommonData2({});
            }
            else {
                setSelectedText('Led Setup');
                setFabriconStages([]);
                setFabriconPlatforms([]);
                setEstimateChildData1([]);
                setFabrication([]);
                setSubChildEstimate({
                    'fabrications': [],
                    'fabrications_st_plt': [],
                    'fabrications_plt': [],
                    'fabrication': [],
                    'Sound': [],
                    'Video': [],
                    'Lights': [],
                    'Genset': [],
                    'Permission': [],
                    'Manpower': [],
                    'Addons': []
                });
                setEstimateChildData([]);
                setEstimateCommonData1([]);
                setEstimateCommonData2({});
            }
            await fetchEstimateData(estimateValue);
            // Fetch child data after fetching estimate data
        } else {
            setSubChildEstimate({
                'fabrications': [],
                'fabrications_st_plt': [],
                'fabrications_plt': [],
                'fabrication': [],
                'Sound': [],
                'Video': [],
                'Lights': [],
                'Genset': [],
                'Permission': [],
                'Manpower': [],
                'Addons': []
            });
            setEstimateChildData1([]);
            setEstimateData([]);
            setEstimateChildData([]);
            setFabriconStages([]);
            setFabriconPlatforms([]);
            setFabrication([]);
            setEstimateCommonData([]);
            setEstimateCommonData1([]);
            setEstimateCommonData2({});
        }
    };


    const handleEstimateChange = (e) => {
        let data = e.target.value.replace("Pax", "").trim();
        setPaxData(e.target.value || '');
        if (selectedEstimate == 'ledsetups' && data == '100') {
            data = "1000"
        }
        setPax(data);
        if (data > 0) {
            fetchEstimateChildData(data);
            setEstimateCommonData1([]);
            setEstimateCommonData2({});
        } else {
            setEstimateChildData([]);
            setFabriconStages([]);
            setFabriconPlatforms([]);
            setFabrication([]);
            setEstimateCommonData([]);
            setEstimateCommonData1([]);
            setEstimateCommonData2({});
        }
    };

    const handleEstimateChildChange = (e) => {
        const { value, checked } = e.target;
        let updatedSelectedOptions;

        if (checked) {
            // If checkbox is checked, add it to the selected options
            updatedSelectedOptions = [...estimateChildData1, value];
        } else {
            // If checkbox is unchecked, remove it from the selected options
            updatedSelectedOptions = estimateChildData1.filter(item => item !== value);
        }
        setSelectedText1(updatedSelectedOptions.join(', '));
        setEstimateChildData1(updatedSelectedOptions);
        fetchEstimateSubChildData(updatedSelectedOptions);

        if (updatedSelectedOptions.length === 0) {
            setFabriconStages([]);
            setFabriconPlatforms([]);
            setFabrication([]);
        }
    };
    const handleCheckBoxAll = (e, selectedName, selectedName1, index = 0,element = '') => {
        const checked = e.target.checked;
        if (selectedName === 'fabricationsLed') {
            const data = fabrication[index].inner_data.map(innerItem => innerItem.id);
            const imageUrl = fabrication[index].image_url; // Get the image_url
            console.log("subChildEstimate = ", subChildEstimate);
        
            setEstimateCommonData1(prev => {
                const newData = new Set(prev);
        
                if (!checked) {
                    setSelectAll({ ...selectAll, [element]: false });
                    data.forEach(id => newData.delete(id));
                    setSubChildEstimate(prev => ({
                        ...prev,
                        [selectedName1]: [] // Reset when unchecked
                    }));
                } else {
                    // Add unique items to subChildEstimate
                    const newItems = fabrication[index].inner_data.filter(innerItem => 
                        !prev[selectedName1]?.some(existingItem => existingItem.id === innerItem.id)
                    );
                    data.forEach(id => newData.add(id));
        
                    // Include image_url with the new items
                    const updatedItems = newItems.map(item => ({
                        ...item,
                        image_url: imageUrl
                    }));
        
                    setSubChildEstimate(prev => ({
                        ...prev,
                        [selectedName1]: [
                            ...(prev[selectedName1] || []),
                            ...updatedItems
                        ]
                    }));
                }
                return Array.from(newData);
            });
        }
        if (selectedName === 'fabrications_stages') {
            const data = checked ? fabriconStages.map(item => item.id) : [];
            console.log("data = ", data);
            setEstimateCommonData1(prev => {
                const newData = new Set(prev);
                if (!checked) {
                    setSelectAll({ ...selectAll, [selectedName]: false });
                    // Remove all fabrications_stages IDs if "Select All" is unchecked
                    fabriconStages.forEach(item => newData.delete(item.id));
                    if (!selectAll['fabrications_platforms']) {
                        setSubChildEstimate({ ...subChildEstimate, [selectedName1]: [] })
                    }
                } else {
                    setSelectAll({ ...selectAll, [selectedName]: true })
                    data.forEach(id => newData.add(id));
                    setSubChildEstimate({ ...subChildEstimate, [selectedName1]: fabriconStages })
                }
                return Array.from(newData);
            });
        }
        if (selectedName === 'fabrications_platforms') {
            const data = checked ? fabriconPlatforms.map(item => item.id) : [];
            setEstimateCommonData1(prev => {
                const newData = new Set(prev);
                if (!checked) {
                    setSelectAll({ ...selectAll, [selectedName]: false });
                    fabriconPlatforms.forEach(item => newData.delete(item.id));
                    if (!selectAll['fabrications_stages']) {
                        setSubChildEstimate({ ...subChildEstimate, [selectedName1]: [] })
                    }
                } else {
                    setSelectAll({ ...selectAll, [selectedName]: true })
                    data.forEach(id => newData.add(id));
                    setSubChildEstimate({ ...subChildEstimate, [selectedName1]: fabriconPlatforms })
                }
                return Array.from(newData);
            });
        }
    };
    const handleCheckboxChange = (e, selectedName, id) => {
        const { checked } = e.target;
        const newSelectedValues = checked
            ? [...(selectedName === 'fabrications' || selectedName === 'fabrications_st_plt' ? estimateCommonData1 : estimateCommonData2[selectedName] || []), id]
            : (selectedName === 'fabrications' || selectedName === 'fabrications_st_plt'
                ? estimateCommonData1.filter(value => value !== id)
                : estimateCommonData2[selectedName].filter(value => value !== id));

        if (selectedName === 'fabrications' || selectedName === 'fabrications_st_plt') {
            setEstimateCommonData1(newSelectedValues);
        } else {
            setEstimateCommonData2(prevData => ({
                ...prevData,
                [selectedName]: newSelectedValues,
            }));
        }
        console.log("estimateCommonData1", estimateCommonData1);
        // Update the subChildEstimate as needed
        updateSubChildEstimate(newSelectedValues, selectedName);
    };

    const updateSubChildEstimate = (selectedValues, selectedName) => {
        const newMatchingItems = [];

        if (selectedValues.length > 0) {
            // Process Fabrication data
            if (fabrication.length > 0) {
                fabrication.forEach(entry => {
                    entry.inner_data.forEach(item => {
                        if (selectedValues.includes(item.id)) {
                            newMatchingItems.push({
                                ...item,
                                image_url: entry.image_url
                            });
                        }
                    });
                });
            }

            // Process Fabricon Stages data
            if (fabriconStages.length > 0) {
                const stagesMatchingItems = fabriconStages.filter(item =>
                    selectedValues.includes(item.id)
                );
                newMatchingItems.push(...stagesMatchingItems);
            }

            // Process Fabricon Platforms data
            if (fabriconPlatforms.length > 0) {
                const platformsMatchingItems = fabriconPlatforms.filter(item =>
                    selectedValues.includes(item.id)
                );
                newMatchingItems.push(...platformsMatchingItems);
            }

            // Process Common Data
            if (estimateCommonData.length > 0) {

                const commonMatchingItems = estimateCommonData.flatMap(category =>
                    category.items.filter(item =>
                        selectedValues.includes(item.id)
                    )
                );
                console.log("estimateCommonData = ", estimateCommonData)
                console.log("commonMatchingIe = ", selectedValues)
                newMatchingItems.push(...commonMatchingItems);
            }
            console.log("newMatchingItems = ",newMatchingItems);
            setSubChildEstimate({ ...subChildEstimate, [selectedName]: newMatchingItems });
        } else {
            setSubChildEstimate({});
        }
    };

    const handleNext = (e) => {
        console.log("selectedText = ", selectedText)
        const combinedArray = Object.values(subChildEstimate).flat().filter(item => item != null);
        console.log("combinedArray = ", combinedArray);
        console.log("estimateCommmonData2 = ", estimateCommonData2);
        e.preventDefault();
        navigate(`/dashboard/nextestimatesevent/${eventId}`,
            {
                state:
                {
                    role: role,
                    pmId: pmId,
                    compId: compId,
                    pmName: pmName,
                    companyName: companyName,
                    clientId: clientId,
                    estimateType: 'ESTIMATE',
                    estimate_data_selected: combinedArray,
                    setup: selectedText,
                    pax: pax,
                    estimate_parent: selectedText1,
                    selectedEstimate: selectedEstimate,
                    estimateData: estimateData,
                    paxData: paxData,
                    estimateChildData: estimateChildData,
                    estimateChildData1: estimateChildData1,
                    fabrication: fabrication,
                    estimateCommonData: estimateCommonData,
                    estimateCommonData1: estimateCommonData1,
                    fabriconStages: fabriconStages,
                    fabriconPlatforms: fabriconPlatforms,
                    estimateCommonData2: estimateCommonData2,
                    subChildEstimate: subChildEstimate
                }
            });
    }
    return (
        <>
            <div className="u-body u-xl-mode" data-lang="en">
                <Header imagTag='../../' role={role} active={{ estimates: 1 }} />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-2">
                        <img
                            src="../../images/logo1.png"
                            alt="Logo"
                            className="img-fluid"
                            style={{ maxHeight: '75px' }}
                        />
                    </div>
                </div>

                <div className="card mt-4 border-0">
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div className="col-md-6 ms-4">
                                <a className="estimateButtonActive">Event</a>
                                <a className="estimateButtonInActive" onClick={(e) => handleExhibition(e)}>Conference/Exhibition</a>
                                <a className="estimateButtonInActive" onClick={(e) => handleIclinic(e)}>Printal Solution</a>
                            </div>
                        </div>

                        <h6 className="mt-5"><b>Select Event Type</b></h6>
                        <select className="form-select" onChange={handleChange} value={selectedEstimate}>
                            <option value=''>select</option>
                            <option value="projector_setups">Projector Set Up</option>
                            <option value="ledsetups">Led Set Up</option>
                        </select>

                        {loading && <div>Loading...</div>}

                        {(estimateData.length > 0) && (
                            <div className="mt-4">
                                <h6><b>Select Estimate Type</b></h6>
                                <select className="form-select" onChange={handleEstimateChange} value={paxData}>
                                    <option value=''> Select</option>
                                    {estimateData.map((item, index) => (
                                        <option key={index} value={item.value}>{item.value}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {loading1 && <div>Loading...</div>}
                        {(estimateChildData.length > 0) && (
                            <div className="mt-4">
                                <fieldset>
                                    <legend><h6><b>Select Estimate Data</b></h6></legend>
                                    {estimateChildData.map((item, index) => (
                                        <div key={index} className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={`checkbox-${index}`}
                                                value={item.name}
                                                checked={estimateChildData1.includes(item.name)} // Check if the item is selected
                                                onChange={handleEstimateChildChange}
                                            />
                                            <label className="form-check-label" htmlFor={`checkbox-${index}`}>
                                                {item.name}
                                            </label>
                                        </div>
                                    ))}
                                </fieldset>
                            </div>
                        )}
                        {loading2 && <div>Loading...</div>}
                        <div className="row">
                            {(fabriconStages.length > 0 || fabriconPlatforms.length > 0 || estimateCommonData.length > 0 || fabrication.length > 0) && (
                                <>
                                    {(fabrication.length > 0 && ['1000', '150', '200', '250', '500'].includes(pax)) && (
                                        <div className="col-12 mt-4">
                                            <fieldset>
                                                <legend><h6><b>Select Fabrication</b></h6></legend>
                                                {fabrication.map((item, index) => (
                                                    <div key={index}>
                                                        <h6>{pax == '1000' ? item.ELEMENT.replace('1000', '100') : item.ELEMENT}
                                                            <input type="checkbox" onChange={(e) => handleCheckBoxAll(e, 'fabricationsLed', 'fabrications', index,item.ELEMENT)} className="ms-3 me-2"></input><label>Select All</label></h6>
                                                        {item.inner_data.map((innerItem, innerIndex) => (
                                                            <div key={innerIndex}>
                                                                <input
                                                                    type="checkbox"
                                                                    value={innerItem.id}
                                                                    className="form-check-input"
                                                                    checked={estimateCommonData1.includes(innerItem.id)}
                                                                    onChange={(e) => handleCheckboxChange(e, 'fabrications', innerItem.id)}
                                                                />
                                                                <label>{innerItem.ELEMENT}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </fieldset>
                                        </div>
                                    )}
                                    {(fabriconStages.length > 0 || fabriconPlatforms.length > 0) && (
                                        <div className="col-12 mt-4">
                                            <fieldset>
                                                <legend><h6><b>Select Fabrication</b></h6></legend>
                                                {fabriconStages.length > 0 && (
                                                    <div>
                                                        <h6>Fabrication Stage <input type="checkbox" onChange={(e) => handleCheckBoxAll(e, 'fabrications_stages', 'fabrications_st_plt')} className="ms-3 me-2"></input><label>Select All</label></h6>

                                                        {fabriconStages.map(item => (
                                                            <div key={item.id}>
                                                                <input
                                                                    type="checkbox"
                                                                    value={item.id}
                                                                    className="form-check-input"
                                                                    checked={estimateCommonData1.includes(item.id)}
                                                                    onChange={(e) => handleCheckboxChange(e, 'fabrications_st_plt', item.id)}
                                                                />
                                                                <label>{item.ELEMENT}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                {fabriconPlatforms.length > 0 && (
                                                    <div>
                                                        <h6>Fabrication Platform <input type="checkbox" onChange={(e) => handleCheckBoxAll(e, 'fabrications_platforms', 'fabrications_st_plt')} className="ms-3 me-2"></input><label>Select All</label></h6>
                                                        {fabriconPlatforms.map(item => (
                                                            <div key={item.id}>
                                                                <input
                                                                    type="checkbox"
                                                                    value={item.id}
                                                                    className="form-check-input"
                                                                    checked={estimateCommonData1.includes(item.id)}
                                                                    onChange={(e) => handleCheckboxChange(e, 'fabrications_st_plt', item.id)}
                                                                />
                                                                <label>{item.ELEMENT}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </fieldset>
                                        </div>
                                    )}
                                    {estimateCommonData.length > 0 && estimateCommonData.map((category) => (
                                        <div className="col-12 mt-4" key={category.category}>
                                            <fieldset>
                                                <legend><h6><b>Select {category.category}</b></h6></legend>
                                                {category.items.map((item) => (
                                                    <div key={item.id}>
                                                        <input
                                                            type="checkbox"
                                                            value={item.id}
                                                            className="form-check-input"
                                                            checked={estimateCommonData2[category.category]?.includes(item.id)}
                                                            onChange={(e) => handleCheckboxChange(e, category.category, item.id)}
                                                        />
                                                        <label> {item.ELEMENT} </label>
                                                    </div>
                                                ))}
                                            </fieldset>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        {(subChildEstimate['Sound']?.length > 0 ||
                            subChildEstimate['Video']?.length > 0 ||
                            subChildEstimate['fabrications']?.length > 0 ||
                            subChildEstimate['fabrications_st_plt']?.length > 0 ||
                            subChildEstimate['Lights']?.length > 0 ||
                            subChildEstimate['Genset']?.length > 0 ||
                            subChildEstimate['Permission']?.length > 0 ||
                            subChildEstimate['Manpower']?.length > 0 ||
                            subChildEstimate['Addons']?.length > 0) && (
                                <p>
                                    <a className="estimateButtonInActive" onClick={(e) => { handleNext(e) }}>Next --></a>
                                </p>
                            )}
                    </div>

                </div>
            </div>
        </>
    );
}
