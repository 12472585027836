import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

const ShowStallStageReferenceDesigns = ({refData = [], show, handleClose }) => {
    console.log("refData = ",refData);
    const [selectedImage, setSelectedImage] = useState(
        (refData.length > 0 && refData[0] && refData[0].img[0]) ? refData[0].img[0] : ''
    );
    const [showImage, setShowImage] = useState(true);
    useEffect(() => {
        if (refData.length > 0 && refData[0] && refData[0].img[0]) {
            setSelectedImage(refData[0].img[0]);
        } else {
            setSelectedImage('');
        }
    }, [refData]);
    const handleImageClick = (imageSrc) => {
        setShowImage(false);
        setTimeout(() => {
            setSelectedImage(imageSrc);
            setShowImage(true);
        }, 500);
    };
    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>SHOW STALL STAGE REFERENCE DESIGNS DATA</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {refData.length > 0 ? (
                    <>
                        <div className={`image-container ${showImage ? 'show' : 'hide'} mt-4`}>
                            {selectedImage && (
                                <div>
                                    <img src={selectedImage} alt="Selected Exhibition" className="img-fluid" />
                                </div>
                            )}
                        </div>
                        <p>
                            {refData[0].img.map((item, index) => (
                                item ? (
                                    <span key={index} onClick={() => handleImageClick(item)} className="image-thumbnail">
                                        <img src={item} alt={`Exhibition ${index + 1}`} width="80" height="80" style={{ cursor: 'pointer', margin: '5px', borderRadius: '5px' }} />
                                    </span>
                                ) : null
                            ))}
                        </p>
                    </>
                ) : (
                    <p>No Stage Reference Available!!!</p>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ShowStallStageReferenceDesigns;
