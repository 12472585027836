import React from 'react';
import { Modal} from 'react-bootstrap';
const RemarkModal = ({remarksData,show,handleClose,estimateType}) => {
    return(
        <>
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Show {estimateType.toLowerCase()} remarks</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {remarksData}
                </Modal.Body>
            </Modal>
        </>
    )
}
export default RemarkModal;