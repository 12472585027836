import React, { useState,useEffect} from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import Header from './Header';

const ShowExhibitionEvent = () => {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const location = useLocation();
    const clientId = location.state?.clientId || sessionStorage.getItem('clientId');
    const role = location.state?.role || '';
    const pmId = location.state?.pmId || '';
    const compId = location.state?.compId || '';
    const pmName = location.state?.pmName || '';
    const companyName = location.state?.companyName || '';
    const [selectedExhibition, setSelectedExhibition] = useState('');
    const [selectedExhibitionSize, setSelectedExhibitionSize] = useState('');
    const [selectedExhibitionSideOpen1, setSelectedExhibitionSideOpen1] = useState([]);
    const [selectedExhibitionSideOpen, setSelectedExhibitionSideOpen] = useState('');
    const [selectedExhibitionSideOpenOption, setSelectedExhibitionSideOpenOption] = useState([]);
    const [selectedExhibitionSideOpenOptionVal, setSelectedExhibitionSideOptionVal] = useState('10');
    useEffect(()=>{
        setSelectedExhibition(location.state?.selectedExhibition || '');
        setSelectedExhibitionSize(location.state?.selectedExhibitionSize || '');
        setSelectedExhibitionSideOpen1(location.state?.selectedExhibitionSideOpen1 || '');
        setSelectedExhibitionSideOpen(location.state?.selectedExhibitionSideOpen || '');
        setSelectedExhibitionSideOpenOption(location.state?.selectedExhibitionSideOpenOption || '');
        setSelectedExhibitionSideOptionVal(location.state?.selectedExhibitionSideOpenOptionVal || '10');
    },[location.state])
    const handleExhibitionChange = (e) => {
        const value = e.target.value;
        setSelectedExhibition(value);
        setSelectedExhibitionSize(''); // Reset size selection when exhibition changes
        setSelectedExhibitionSideOpen(''); // Reset side open when exhibition changes
        setSelectedExhibitionSideOpen1([]);
        setSelectedExhibitionSideOpenOption(null);
    };
   
    const handleExhibitionSize = (e) => {
        const ExhibitionVal = e.target.value;
        if (selectedExhibition === 'Regional Stall Branding') {
            switch (ExhibitionVal) {
                case '2m x 2m':
                case '3m x 2m':
                case '3m x 3m':
                case '4m x 2m':
                case '4m x 3m':
                case '5m x 2m':
                case '5m x 3m':
                case '6m x 3m':
                    setSelectedExhibitionSideOpen1(['1 Side Open', '2 Side Open']);
                    break;
                case '6m x 2m':
                    setSelectedExhibitionSideOpen1(['1 Side Open', '3 Side Open']);
                    break;
                default:
                    setSelectedExhibitionSideOpen1([]);
            }
            setSelectedExhibitionSideOpenOption([]);
        }
        else if(selectedExhibition == 'National Stall Branding'){
          
            const sideOpenMap = {
                '2m x 2m' : ['1 Side Open'],
                '3m x 2m' : ['1 Side Open'],
                '3m x 3m' : ['1 Side Open','2 Side Open'],
                '4m x 8m' : ['2 Side Open','3 Side Open'],
                '4m x 2m' : ['1 Side Open','2 Side Open'],
                '4m x 3m' : ['1 Side Open','2 Side Open'],
                '4m x 4m' : ['1 Side Open','2 Side Open','3 Side Open'],
                '5m x 2m' : ['1 Side Open'],
                '5m x 3m' : ['2 Side Open'],
                '5m x 5m' : ['2 Side Open','3 Side Open'],
                '6m x 2m' : ['1 Side Open','3 Side Open'],
                '6m x 4m' : ['3 Side Open','4 Side Open'],
                '6m x 6m' : ['2 Side Open','3 Side Open'],
                '8m x 8m' : ['3 Side Open'],
                '9m x 3m' : ['1 Side Open','2 Side Open','4 Side Open'],
                '9m x 5m' : ['2 Side Open'],
                '9m x 6m' : ['2 Side Open','4 Side Open'],
                '9m x 9m' : ['3 Side Open'],
                '10m x 5m' : ['2 Side Open','3 Side Open','4 Side Open'],
                '10m x 6m' : ['3 Side Open'],
                '10m x 8m' : ['3 Side Open'],
                '10m x 10m' : ['2 Side Open','4 Side Open'],
                '12m x 9m' : ['3 Side Open'],
                '12m x 12m' : ['4 Side Open'],
            };
            const optionSideOpen = sideOpenMap[ExhibitionVal] || [];
            setSelectedExhibitionSideOpen1(optionSideOpen);
            setSelectedExhibitionSideOpenOption([]);
        }
        setSelectedExhibitionSideOpen([]);
        setSelectedExhibitionSize(ExhibitionVal);
    };

    const handleExhibitionSideOpen = (e) => {
        const sideOpen = e.target.value;
        setSelectedExhibitionSideOpen(sideOpen);
       
        // Mapping options based on sideOpen and selectedExhibitionSideOpen
        if (selectedExhibition === 'Regional Stall Branding') {
            const optionsMap = {
                '2m x 2m': {
                    '1 Side Open': ['Option 1', 'Option 2','Option 3'],
                    '2 Side Open': [],
                },
                '3m x 2m': {
                    '1 Side Open': ['Option 1', 'Option 2'],
                    '2 Side Open': [],
                },
                '3m x 3m': {
                    '1 Side Open': ['Option 1', 'Option 2'],
                    '2 Side Open': [],
                },
                '4m x 2m': {
                    '1 Side Open': ['Option 1', 'Option 2'],
                    '2 Side Open': ['Option 1', 'Option 2'],
                },
                '4m x 3m': {
                    '1 Side Open': ['Option 1', 'Option 2'],
                    '2 Side Open': [],
                },
                '5m x 2m': {
                    '1 Side Open': ['Option 1'],
                    '2 Side Open': [],
                },
                '5m x 3m': {
                    '1 Side Open': [],
                    '3 Side Open': [],
                },
                '6m x 2m': {
                    '1 Side Open': ['Option 1', 'Option 2'],
                    '3 Side Open': [],
                },
                '6m x 3m': {
                    '2 Side Open': ['Option 1', 'Option 2'],
                    '3 Side Open': [],
                },
            };
            const options = optionsMap[selectedExhibitionSize]?.[sideOpen] || [];
            console.log("options = ",selectedExhibitionSize);
            setSelectedExhibitionSideOpenOption(options);
            if(options.length < 1){
                setSelectedExhibitionSideOptionVal('10');
            }
        }
        else if(selectedExhibition == 'National Stall Branding'){
            const optionsMap = {
                '2m x 2m': {
                    '1 Side Open': ['Option 1', 'Option 2'],
                },
                '3m x 2m': {
                    '1 Side Open': []
                },
                '3m x 3m' : {
                    '1 Side Open' : ['Option 1','Option 2'],
                    '2 Side Open' : ['Option 1','Option 2'],
                },
                '4m x 8m' : {
                    '3 Side Open' : [],
                    '2 Side Open' : []
                },
                '4m x 2m' : {
                    '1 Side Open' : ['Option 1','Option 2'],
                    '2 Side Opne' : []
                },
                '4m x 3m' : {
                    '1 Side Open' : ['Option 1'],
                    '2 Side Open' : ['Option 1','Option 2']
                },
                '4m x 4m' : {
                    '1 Side Open' : ['Option 1'],
                    '2 Side Open' : [],
                    '3 Side Open' : []
                },
                '5m x 2m' : {
                    '1 Side Open' : ['Option 1','Option 2']
                },
                '5m x 3m' : {
                    '2 Side Open' : ['Option 1','Option 2']
                },
                '5m x 5m' : {
                    '2 Side Open' : ['Option 1','Option 2'],
                    '3 Side Open' : []
                },
                '6m x 4m' : {
                    '3 Side Open' : [],
                    '4 Side Open' : []
                },
                '6m x 6m' : {
                    '2 Side Open' : ['Option 1','Option 2'],
                    '3 Side Open' : ['Option 1','Option 2']
                },
                '8m x 8m' : {
                    '3 Side Open' : ['Option 1','Option 2']
                },
                '9m x 3m' : {
                    '1 Side Open' : [],
                    '2 Side Open' : [],
                    '4 Side Open' : []
                },
                '9m x 5m' : {
                    '2 Side Open' : []
                },
                '9m x 6m' : {
                    '2 Side Open' : [],
                    '4 Side Open' : []
                },
                '9m x 9m' : {
                    '3 Side Open' : []
                },
                '10m x 5m' : {
                    '2 Side Open' : [],
                    '3 Side Open' : ['Option 1'],
                    '4 Side Open' : []
                },
                '10m x 6m' : {
                    '3 Side Open' : ['Option 1','Option 2']
                },
                '10m x 8m' : {
                    '3 Side Open' : []
                },
                '10m x 10m' : {
                    '2 Side Open' : ['Option 1','Option 2'],
                    '4 Side Open' : []
                },
                '12m x 9m' : {
                    '3 Side Open' : []
                },
                '12m x 12m' : {
                    '4 Side Open' : ['Option 1','Option 2']
                }
            }  
            const options = optionsMap[selectedExhibitionSize]?.[sideOpen] || [];
            setSelectedExhibitionSideOpenOption(options);
            console.log("options = ",options);
            if(options.length < 1){
                setSelectedExhibitionSideOptionVal('10');
            }
        }
    };
    

    const handleExhibitionSideOpenOption = (e) => {
        setSelectedExhibitionSideOptionVal(e.target.value);
    }
    // Redirect to Estimate event page when client click on EVENT button
    const handleEvent = (e) => {
        e.preventDefault();
        navigate(`/dashboard/showestimateseevent/${eventId}`, {
            state: {
                clientId: clientId,
                role: role,
                pmId: pmId,
                compId: compId,
                pmName: pmName,
                companyName: companyName,
            }
        });
    };
    // Redirect to Estimate Iclinic page when client click on ICLINIC SOLUTION button
    const handleIclinic = (e) => {
        e.preventDefault();
        navigate(`/dashboard/showiclinicevent/${eventId}`,
            {
                state: {
                    clientId: clientId,
                    role: role,
                    pmId: pmId,
                    compId: compId,
                    pmName: pmName,
                    companyName: companyName,
                }
            }
        );
    }
    const handleNext = (e) => {
        console.log("selectedExhibitionSideOpenOptionVal = ",selectedExhibitionSideOpenOptionVal);
        console.log("clientId = ", clientId);
        e.preventDefault();
        navigate(`/dashboard/nextexhibitionevent/${eventId}`,
            {
                state:
                {
                    estimateType: 'EXHIBITION',
                    selectedExhibition: selectedExhibition,
                    selectedExhibitionSize: selectedExhibitionSize,
                    selectedExhibitionSideOpen: selectedExhibitionSideOpen,
                    selectedExhibitionSideOpenOptionVal : selectedExhibitionSideOpenOptionVal || '10',
                    selectedExhibitionSideOpen1:selectedExhibitionSideOpen1,
                    selectedExhibitionSideOpenOption:selectedExhibitionSideOpenOption,
                    role: role,
                    pmId, pmId,
                    clientId: clientId,
                    compId: compId,
                    pmName: pmName,
                    companyName: companyName
                }
            });
    }
    return (
        <>
            <div className="u-body u-xl-mode" data-lang="en">
                <Header imagTag='../../' role={role} active={{ estimates: 1 }} />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-2">
                        <img
                            src="../../images/logo1.png"
                            alt="Logo"
                            className="img-fluid"
                            style={{ maxHeight: '75px' }}
                        />
                    </div>
                </div>
                <div className="card mt-4 border-0">
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div className="col-md-6 ms-4">
                                <a className="estimateButtonInActive" onClick={handleEvent}>Event</a>
                                <a className="estimateButtonActive">Conference/Exhibition</a>
                                <a className="estimateButtonInActive" onClick={(e) => handleIclinic(e)}>Printal Solution</a>
                            </div>
                        </div>
                        <h6 className="mt-5"><b>Select Exhibition Type</b></h6>
                        <select className="form-select" onChange={handleExhibitionChange} value={selectedExhibition}>
                            <option value="">Select</option>
                            <option value="Regional Stall Branding">Regional Stall Branding</option>
                            <option value="National Stall Branding">National Stall Branding</option>
                        </select>
                        {selectedExhibition && (
                            <div className="mt-4">
                                <h6><b>Select Exhibition Size</b></h6>
                                <select className="form-select" onChange={handleExhibitionSize} value={selectedExhibitionSize}>
                                    <option value="">Select</option>
                                    <option value="2m x 2m">2m x 2m</option>
                                    <option value="3m x 2m">3m x 2m</option>
                                    <option value="3m x 3m">3m x 3m</option>
                                    <option value="4m x 2m">4m x 2m</option>
                                    <option value="5m x 2m">5m x 2m</option>
                                    <option value="6m x 2m">6m x 2m</option>
                                    <option value="4m x 3m">4m x 3m</option>
                                    <option value="5m x 3m">5m x 3m</option>
                                    <option value="6m x 3m">6m x 3m</option>
                                    {selectedExhibition === 'National Stall Branding' && (
                                        <>
                                            <option value="6m x 6m">6m x 6m</option>
                                            <option value="5m x 5m">5m x 5m</option>
                                            <option value="4m x 8m">4m x 8m</option>
                                            <option value="4m x 4m">4m x 4m</option>
                                            <option value="6m x 4m">6m x 4m</option>
                                            <option value="9m x 6m">9m x 6m</option>
                                            <option value="9m x 3m">9m x 3m</option>
                                            <option value="9m x 5m">9m x 5m</option>
                                            <option value="9m x 9m">9m x 9m</option>
                                            <option value="10m x 5m">10m x 5m</option>
                                            <option value="10m x 6m">10m x 6m</option>
                                            <option value="10m x 10m">10m x 10m</option>
                                            <option value="8m x 8m">8m x 8m</option>
                                            <option value="10m x 8m">10m x 8m</option>
                                            <option value="12m x 9m">12m x 9m</option>
                                            <option value="12m x 12m">12m x 12m</option>
                                        </>
                                    )}
                                </select>
                            </div>
                        )}
                        {Array.isArray(selectedExhibitionSideOpen1) && selectedExhibitionSideOpen1.length > 0 && (
                            <div className="mt-4">
                                <h6><b>Select Exhibition Side Open Option</b></h6>
                                <select className="form-select" onChange={handleExhibitionSideOpen} value={selectedExhibitionSideOpen}>
                                    <option value="">Select</option>
                                    {selectedExhibitionSideOpen1.map((value1) => (
                                        <option key={value1} value={value1}>{value1}</option>
                                    ))}
                                </select>
                            </div>
                        )}

                        {Array.isArray(selectedExhibitionSideOpenOption) && selectedExhibitionSideOpenOption.length > 0 && (
                            <div className="mt-4">
                                <h6><b>Select Additional Options</b></h6>
                                <select className="form-select" onChange={handleExhibitionSideOpenOption} value={selectedExhibitionSideOpenOptionVal}>
                                    <option value="">Select</option>
                                    {selectedExhibitionSideOpenOption.map((value1, index) => (
                                        <option key={index} value={index + 1}>{value1}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {selectedExhibitionSideOpenOptionVal && (
                            <p>
                                <a className="estimateButtonInActive" onClick={(e) => { handleNext(e) }}>Next  --></a>
                            </p>
                        )}

                    </div>
                </div>
            </div>
        </>
    );
}

export default ShowExhibitionEvent;
