import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import Header from './Header';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

const NextExhibitionEvent = () => {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const location = useLocation();
    const clientId = location.state?.clientId || '';
    const role = location.state?.role || '';
    const pmId = location.state?.pmId || '';
    const compId = location.state?.compId || '';
    const pmName = location.state?.pmName || '';
    const selectedExhibition = location.state?.selectedExhibition || '';
    const selectedExhibitionSize = location.state?.selectedExhibitionSize || '';
    const selectedExhibitionSideOpen = location.state?.selectedExhibitionSideOpen || '';
    const selectedExhibitionSideOpenOptionVal = location.state?.selectedExhibitionSideOpenOptionVal || '10';
    const selectedExhibitionSideOpen1 = location.state?.selectedExhibitionSideOpen1 || '';
    const selectedExhibitionSideOpenOption = location.state?.selectedExhibitionSideOpenOption || '';
    const companyName = location.state?.companyName || '';
    const [selectedImage, setSelectedImage] = useState('');
    const [showImage, setShowImage] = useState(true);
    const [dataNotFound, setDataNotFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const [description, setDescription] = useState([]);
    const [images, setImages] = useState([]);
    const [exhibitionId, setExhibitionId] = useState(0);
    const [size, setSize] = useState('');
    const [branding, setBranding] = useState('');
    const [sideOpen, setSideOpen] = useState('');
    const [approximateCost, setApproximateCost] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [cartCount, setCartCount] = useState(0);
    const [cartData, setCartData] = useState([]);

    const handleImageClick = (imageSrc) => {
        setShowImage(false);
        setTimeout(() => {
            setSelectedImage(imageSrc);
            setShowImage(true);
        }, 500);
    };

    const handleQuantityChange = (delta) => {
        setQuantity(prevQuantity => Math.max(1, prevQuantity + delta));
    };

    const handleAddToCart = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/cart/add`, {
                exhibition_id: exhibitionId,
                quantity: quantity,
                user_id: clientId,
                event_id: eventId
            });
            if (response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Added To Cart',
                    text: 'Your Product has been Successfully Added to Cart!',
                    confirmButtonText: 'OK',
                    preConfirm: () => {
                        fetchCartData();
                    }
                });
            }
        } catch (e) {
            console.error('Error adding to cart:', e.message);
        }
    };

    const handleBack = (e) => {
        e.preventDefault();
        navigate(`/dashboard/showexhibitionevent/${eventId}`,
            {state:
                {
                    eventId,eventId,
                    role:role,
                    pmId : pmId,
                    compId : compId,
                    pmName : pmName,
                    companyName : companyName,
                    clientId : clientId,
                    selectedExhibition:selectedExhibition,
                    selectedExhibitionSize:selectedExhibitionSize,
                    selectedExhibitionSideOpen: selectedExhibitionSideOpen,
                    selectedExhibitionSideOpenOptionVal : selectedExhibitionSideOpenOptionVal,
                    selectedExhibitionSideOpen1:selectedExhibitionSideOpen1,
                    selectedExhibitionSideOpenOption:selectedExhibitionSideOpenOption
                }
            });
    };

    // const handleSendToPm = (e) => {
    //     e.preventDefault();
    //     setIsSendedPM(true);
    //     Swal.fire({
    //         icon: 'success',
    //         title: 'Send To PM',
    //         text: 'Your exhibition has been sent to PM!',
    //     });
    // };

    const fetchCartData = useCallback(async () => {
        try {
            const parsedEventId = parseInt(eventId, 10);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/cart`, {
                user_id: clientId,
                event_id: parsedEventId
            });
            if (response.status === 200) {
                if (response.data.length > 0) {
                    setCartData(response.data);
                    setCartCount(response.data.length);
                    
                } else {
                    setCartData([]);
                    setCartCount(0);
                }
            }
        } catch (e) {
            setCartCount(0);
            console.error('Error fetching cart data:', e.message);
        }
    }, [clientId, eventId]);

    const fetchExhibitionData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_exhibition_data`, {
                branding: location.state?.selectedExhibition,
                size: location.state?.selectedExhibitionSize,
                side_open: location.state?.selectedExhibitionSideOpen,
                option : selectedExhibitionSideOpenOptionVal || '10',
            });
            if (response.status === 200) {
                setDataNotFound(false);
                setSize(response.data[0].size);
                setBranding(response.data[0].Branding);
                setSideOpen(response.data[0].side_open);
                setApproximateCost(response.data[0].approximate_cost);
                setExhibitionId(response.data[0].id);
                setImages(response.data[0].img);
                setSelectedImage(response.data[0].img[0]);
                setDescription(response.data[0].description);
            }
        } catch (error) {
            setDataNotFound(true);
            setSize('');
            setBranding('');
            setSideOpen('');
            setApproximateCost('');
            setExhibitionId(0);
            setDescription([]);
            setImages([]);
            console.error('Error fetching exhibition data:', error.message);
        } finally {
            setLoading(false);
        }
    }, [location.state]);

    useEffect(() => {
        fetchExhibitionData();
    }, [fetchExhibitionData]);

    useEffect(() => {
        fetchCartData();
    }, [fetchCartData, exhibitionId]);

    const handleCartItems = () =>{
        console.log("cartdata = ",cartData);
        navigate(`/dashboard/cartitems/${eventId}`,{
            state:
            {
                role: role,
                pmId: pmId,
                estimateType: 'EXHIBITION',
                estimate_data_selected: cartData,
                setup: branding,
                pax: 5000,
                estimate_parent: sideOpen,
                compId : compId,
                pmName : pmName,
                companyName : companyName,
                clientId : clientId,
                exhibitionId : exhibitionId
            }
        });
    }
    const totalCost = quantity * approximateCost;

    return (
        <>
            <div className="u-body u-xl-mode" data-lang="en">
                <Header imagTag='../../' role = {role} active = {{estimates : 1}} />
                {loading ? (
                    <div className="text-center mt-5">Loading...</div>
                ) : (
                    <div className="container">
                        {dataNotFound ? (
                            <div className="col-12 text-center mt-5">
                                <h4>Data Not Found</h4>
                                <p>Sorry, we couldn't find the data you're looking for.</p>
                            </div>
                        ) : (
                            <>
                                <div className="row justify-content-md-end justify-content-lg-end justify-content-sm-center justify-content-xs-center">
                                    <div className="col-md-2 col-sm-2 col-xs-2">
                                        <button
                                            type="button"
                                            className="btn btn-primary-cart position-relative bottom-0 end-0 ms-5"
                                            onClick = {handleCartItems}
                                        >
                                            <FontAwesomeIcon icon={faShoppingCart} />
                                             
                                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                    {cartCount}
                                                </span>
                                            
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                                        <div className={`image-container ${showImage ? 'show' : 'hide'} mt-4`}>
                                            {selectedImage && (
                                                <div>
                                                    <img src={selectedImage} alt="Selected Exhibition" className="img-fluid" />
                                                </div>
                                            )}
                                        </div>
                                        <p>
                                            {images.map((imageSrc, index) => (
                                                <span key={index} onClick={() => handleImageClick(imageSrc)} className="image-thumbnail">
                                                    <img src={imageSrc} alt={`Exhibition ${index + 1}`} width="80" height="80" style={{ cursor: 'pointer', margin: '5px', borderRadius: '5px' }} />
                                                </span>
                                            ))}
                                        </p>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                                        <h3>{branding}</h3>
                                        <h6><b>Side Open: </b>{sideOpen}</h6>
                                        <h6><b>Size: </b>{size}</h6>
                                        <h5 className="mt-4">
                                            <ul className="list">
                                                {description.map((item, index) => (
                                                    <li className="list-item" key={index}>{item}</li>
                                                ))}
                                            </ul>
                                        </h5>
                                        <div className="quantity-controls">
                                            <button className="quantity-button" onClick={() => handleQuantityChange(-1)} disabled={quantity <= 1}>-</button>
                                            <span className="quantity-display">Qty: {quantity}</span>
                                            <button className="quantity-button" onClick={() => handleQuantityChange(1)}>+</button>
                                        </div>
                                        <p className="mt-3"><strong>Approximate Cost:</strong> {totalCost.toLocaleString()}</p>
                                        <button className="btn btn-primary mt-3" onClick={handleAddToCart}>
                                            <FontAwesomeIcon icon={faShoppingCart} /> <span className="ms-2">Add to Cart</span>
                                        </button>
                                        <button className="btn btn-success btn-lg mt-3 ms-4" onClick={handleBack}>BACK</button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default NextExhibitionEvent;
