import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import Header from './Header';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
const NextIclinicEvent = () => {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const location = useLocation();
    const clientId = location.state?.clientId || '';
    const role = location.state?.role || '';
    const pmId = location.state?.pmId || '';
    const compId = location.state?.compId || '';
    const pmName = location.state?.pmName || '';
    const companyName = location.state?.companyName || '';
    const [selectedImage, setSelectedImage] = useState('');
    const [showImage, setShowImage] = useState(true);
    const [dataNotFound, setDataNotFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const [description, setDescription] = useState([]);
    const [images, setImages] = useState([]);
    const [exhibitionId, setExhibitionId] = useState(0);
    const [inclinicData, setInclinicData] = useState('');
    const [branding, setBranding] = useState('');
    const [inclinicPrinting, setInclinicPrinting] = useState('');
    const [approximateCost, setApproximateCost] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [cartCount, setCartCount] = useState(0);
    const [cartData, setCartData] = useState([]);

    const handleImageClick = (imageSrc) => {
        setShowImage(false);
        setTimeout(() => {
            setSelectedImage(imageSrc);
            setShowImage(true);
        }, 500);
    };

    const handleQuantityChange = (delta) => {
        setQuantity(prevQuantity => Math.max(1, prevQuantity + delta));
    };

    const handleAddToCart = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/printel-cart/add`, {
                printel_id: exhibitionId,
                quantity: quantity,
                user_id: clientId,
                event_id: eventId
            });
            if (response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Added To Cart',
                    text: 'Your Product has been Successfully Added to Cart!',
                    confirmButtonText: 'OK',
                    preConfirm: () => {
                        fetchCartData();
                    }
                });
            }
        } catch (e) {
            console.error('Error adding to cart:', e.message);
        }
    };

    const handleBack = (e) => {
        e.preventDefault();
        navigate(`/dashboard/showiclinicevent/${eventId}`,
            {
                state:
                {
                    eventId, eventId,
                    role: role,
                    pmId: pmId,
                    compId: compId,
                    pmName: pmName,
                    companyName: companyName,
                    clientId: clientId,
                    selectedIclinic:location.state?.selectedIclinic,
                    selectedIclinicDisplays:location.state?.selectedIclinicDisplays,
                    Branding:location.state?.Branding,
                    printingData:location.state?.printingData
                }
            });
    };

    const fetchCartData = useCallback(async () => {
        try {
            const parsedEventId = parseInt(eventId, 10);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/printel-cart`, {
                user_id: clientId,
                event_id: parsedEventId
            });
            if (response.status === 200) {
                if (response.data.length > 0) {
                    setCartData(response.data);
                    setCartCount(response.data.length);

                } else {
                    setCartData([]);
                    setCartCount(0);
                }
            }
        } catch (e) {
            setCartCount(0);
            console.error('Error fetching cart data:', e.message);
        }
    }, [clientId, eventId]);
    const extractValues = (obj) => {
        let values = [];
        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                values = values.concat(extractValues(obj[key]));
            } else {
                values.push(obj[key]);
            }

        }
        return values;
    };
    const fetchIclinicData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_inclinic_printing_layout`, {
                inclinic_data: location.state?.selectedIclinic,
                inclinic_printing: location.state?.selectedIclinicDisplays,
                Branding: location.state?.Branding
            });

            if (response.status === 200) {
                console.log("response = ", response.data[0]);
                setDataNotFound(false);
                setInclinicData(response.data[0].inclinic_data);
                setBranding(response.data[0].Branding);
                setInclinicPrinting(response.data[0].inclinic_printing);
                setApproximateCost(response.data[0].approximate_cost);
                setExhibitionId(response.data[0].id);
                setImages(response.data[0].img);
                setSelectedImage(response.data[0].img[0]);
                const description1 = [response.data[0].description]
                const allValues = description1.flatMap(item => extractValues(item));
                console.log("all Vales = ", allValues);
                setDescription(allValues);
            }
        } catch (error) {
            setDataNotFound(true);
            setInclinicData('');
            setBranding('');
            setInclinicPrinting('');
            setApproximateCost('');
            setExhibitionId(0);
            setDescription([]);
            setImages([]);
            console.error('Error fetching exhibition data:', error.message);
        } finally {
            setLoading(false);
        }
    }, [location.state]);

    useEffect(() => {
        fetchIclinicData();
    }, [fetchIclinicData]);

    useEffect(() => {
        fetchCartData();
    }, [fetchCartData, exhibitionId]);

    const handleCartItems = () => {
        console.log("cartdata = ", cartData);
        navigate(`/dashboard/cartitems/${eventId}`, {
            state:
            {
                role: role,
                pmId: pmId,
                estimateType: 'INCLINIC',
                estimate_data_selected: cartData,
                setup: branding,
                pax: 5000,
                estimate_parent: inclinicPrinting,
                compId: compId,
                pmName: pmName,
                companyName: companyName,
                clientId: clientId,
                exhibitionId: exhibitionId
            }
        });
    }
    const totalCost = quantity * approximateCost;

    return (
        <>
            <div className="u-body u-xl-mode" data-lang="en">
                <Header imagTag='../../' role={role} active={{ estimates: 1 }} />
                {loading ? (
                    <div className="text-center mt-5">Loading...</div>
                ) : (
                    <div className="container">
                        {dataNotFound ? (
                            <div className="col-12 text-center mt-5">
                                <h4>Data Not Found</h4>
                                <p>Sorry, we couldn't find the data you're looking for.</p>
                            </div>
                        ) : (
                            <>
                                <div className="row justify-content-md-end justify-content-lg-end justify-content-sm-center justify-content-xs-center">
                                    <div className="col-md-2 col-sm-2 col-xs-2">
                                        <button
                                            type="button"
                                            className="btn btn-primary-cart position-relative bottom-0 end-0 ms-5"
                                            onClick={handleCartItems}
                                        >
                                            <FontAwesomeIcon icon={faShoppingCart} />

                                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                {cartCount}
                                            </span>

                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                                        <div className={`image-container ${showImage ? 'show' : 'hide'} mt-4`}>
                                            {selectedImage && (
                                                <div>
                                                    <img src={selectedImage} alt="Selected Exhibition" className="img-fluid" />
                                                </div>
                                            )}
                                        </div>
                                        <p>
                                            {images.map((imageSrc, index) => (
                                                <span key={index} onClick={() => handleImageClick(imageSrc)} className="image-thumbnail">
                                                    <img src={imageSrc} alt={`Exhibition ${index + 1}`} width="80" height="80" style={{ cursor: 'pointer', margin: '5px', borderRadius: '5px' }} />
                                                </span>
                                            ))}
                                        </p>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                                        <h3 style={{ textTransform: 'uppercase' }}>{branding}</h3>
                                        <h6><b>Inclinic Printing: </b>{inclinicPrinting}</h6>
                                        <h6><b>Inclinic Data: </b>{inclinicData}</h6>
                                        <h5 className="mt-4">
                                            <ul className="list">
                                                {description.map((item, index) => {
                                                    const isUrl = typeof item === 'string' && item.startsWith('http');
                                                    return (
                                                        <li className="list-item" key={index}>
                                                            {isUrl ? (
                                                                <a href={item} target="_blank" rel="noopener noreferrer">
                                                                    {item}
                                                                </a>
                                                            ) : (
                                                                item
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </h5>

                                        <div className="quantity-controls">
                                            <button className="quantity-button" onClick={() => handleQuantityChange(-1)} disabled={quantity <= 1}>-</button>
                                            <span className="quantity-display">Qty: {quantity}</span>
                                            <button className="quantity-button" onClick={() => handleQuantityChange(1)}>+</button>
                                        </div>
                                        <p className="mt-3"><strong>Approximate Cost:</strong> {totalCost.toLocaleString()}</p>
                                        <button className="btn btn-primary mt-3" onClick={handleAddToCart}>
                                            <FontAwesomeIcon icon={faShoppingCart} /> <span className="ms-2">Add to Cart</span>
                                        </button>
                                        <button className="btn btn-success btn-lg mt-3 ms-4" onClick={handleBack}>BACK</button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default NextIclinicEvent;