import React from 'react';
import { Modal} from 'react-bootstrap';
const ViewCreativeImageModal = ({show,handleClose,CreativeImageData}) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>SHOW CREATIVE IMAGES</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {CreativeImageData != null ? (
                    <img src={CreativeImageData} style = {{width : '100%', height:'100%'}} alt="Creative Images" />
                ) : (
                    <p>No Po Data Available!!!.</p>
                )}
            </Modal.Body>
        </Modal>
    )
}
export default ViewCreativeImageModal;