import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import Header from './Header';
import axios from 'axios';
import Swal from 'sweetalert2';
import ShowStallStageReferenceDesigns from './ShowStallStageReferenceDesigns';

const ViewEstimates = () => {
    const location = useLocation();
    const { estimateId } = useParams();
    const eventId = location.state.eventId;
    const navigate = useNavigate();
    const role = location.state?.role || '';
    const pmId = location.state?.pmId || '';
    const totalCost = location.state?.totalCost || 0;
    const products = location.state.estimate_data_selected || [];
    const ELEMENT = products[0]?.ELEMENT || '';
    console.log("element = ",ELEMENT);
    const pmName = location.state?.pmName || 'Unknown PM';
    const companyName = location.state?.companyName || 'Unknown Company';
    const estimateType = location.state?.estimateType || '';
    const disableApprove = location.state?.disableApprove || false;
    const [stageRefData, setStageRefData] = useState([]);
    const [showStageRefModal, setShowStageRefModal] = useState(false);
    const handleApprove = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/updateStatus`,
                {
                    estimate_id: estimateId,
                    status: "Approved By Client"
                });
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Approved By Client',
                    text: response.data.message || 'Request was successful.',
                    confirmButtonText: 'OK',
                    preConfirm: () => {
                        navigate(`/dashboard/showestimates/${eventId}`,
                            {
                                state:
                                {
                                    pmId: pmId,
                                    role: role,
                                    pmName: pmName,
                                    companyName: companyName,
                                }
                            });
                    }
                });

            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Unexpected Response',
                    text: `Received unexpected status code: ${response.status}`,
                });
            }
        }
        catch (e) {

        }
    }
    const handleCloseRefModal = () => {
        setShowStageRefModal(false);
    }
    const handleShowStageRefDesigns = async (e) => {
        e.preventDefault();
        try {
            setStageRefData(products);
            setShowStageRefModal(true);
        }
        catch (e) {
            setStageRefData([]);
            setShowStageRefModal(true);
        }
    }
    const HeaderMenu = () => {

        const handleBack = (e) => {
            e.preventDefault();
            navigate(`/dashboard/showestimates/${eventId}`,
                {
                    state:
                    {
                        pmId: pmId,
                        role: role,
                        pmName: pmName,
                        companyName: companyName,
                    }
                });
        }
        return (
            <div className="product-cart-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 clearfix">
                            <h2 className="section-head"><b>{role == 1 ? `VIEW ${estimateType} CART (CLIENT SIDE)` : `VIEW ${estimateType} CART (PM SIDE)`}</b></h2>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>IMAGE</th>
                                        <th>ELEMENT</th>
                                        <th>SIZE</th>
                                        <th width="120px">COST</th>
                                        <th width="100px">QTY</th>
                                        <th width="100px">SUB TOTAL</th>
                                        {role === 1 && <th>Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.length > 0 ? (
                                        products.map(product => (
                                            <tr key={product.id} className="item-row">
                                                <td>
                                                    <img src={product.image_url} alt={product.ELEMENT} style={{ width: '100px' }} />
                                                </td>
                                                <td>{product.ELEMENT}</td>
                                                <td>{product.SIZE}</td>
                                                <td>
                                                    <span className="product-price">{product.COST}</span>
                                                </td>
                                                <td>
                                                    <span
                                                        className="product-qty"
                                                    >{product.QTY}</span>
                                                </td>
                                                <td>
                                                    {product.SUB_TOTAL}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={role === 1 ? 7 : 6} className="text-center">No products available</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan={role === 1 ? 6 : 5} className="text-end"><strong>Total Cost:</strong></td>
                                        <td>
                                            <strong>{totalCost}</strong>
                                        </td>
                                        {role === 1 && <td></td>}
                                    </tr>
                                </tbody>
                            </table>
                            <button className="btn btn-success btn-lg" onClick={(e) => handleBack(e)}>BACK</button>
                            {role === 1 ? (
                                !disableApprove ? (
                                    <button className="btn btn-primary btn-lg ms-4" onClick={(e) => handleApprove(e)}>
                                        APPROVE
                                    </button>
                                ) : null
                            ) : ELEMENT === 'Regional Stall Branding' || ELEMENT == 'National Stall Branding' ? (
                                <a className="btn btn-primary btn-lg ms-4" onClick={(e) => handleShowStageRefDesigns(e)} style={{ color: 'white' }}>
                                    SHOW STALL REFERENCE DESIGNS
                                </a>
                            ) : null}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <>
            <div className="u-body u-xl-mode" data-lang="en">
                <Header imagTag='../../../../' role={role} active={{ estimates: 1 }} />
            </div>
            <HeaderMenu />
            <ShowStallStageReferenceDesigns
                role={role}
                refData={stageRefData}
                show={showStageRefModal}
                handleClose={handleCloseRefModal}
            />
        </>
    );

}
export default ViewEstimates;