import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import './cart.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import Header from './Header';
import StageReferenceModal from './StageReferenceModal';
const HeaderMenu = () => {
    const [products, setProducts] = useState([]);
    const [totalCost, setTotalCost] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const { eventId } = useParams();
    const estimateType = location.state?.estimateType || ''
    const [loading, setLoading] = useState(false);
    const [stageRefData,setStageRefData] = useState([]);
    const [showStageRefModal,setShowStageRefModal] = useState(false);
    console.log("estimateType = ",estimateType);
    useEffect(() => {
        if (location.state && location.state.estimate_data_selected) {
            const subChildEstimate = location.state.estimate_data_selected;

            // Initialize SUB_TOTAL for each product
            const initializedProducts = subChildEstimate.map(product => {
                const cost = parseFloat(product.COST) || 0;
                const qty = parseInt(product.QTY) || 0;
                const subTotal = (cost * qty).toFixed(2);

                return {
                    ...product,
                    SUB_TOTAL: subTotal
                };
            });

            setProducts(initializedProducts);
            computeTotalCost(initializedProducts);
        }
    }, [location.state]);
    const computeTotalCost = (products) => {
        const total = products.reduce((acc, product) => {
            const cost = parseFloat(product.COST) || 0;
            const qty = parseInt(product.QTY) || 0;
            return acc + (cost * qty);
        }, 0);
        setTotalCost(total.toFixed(2));
    };

    const handleQuantityChange = (id, value) => {
        const newQty = parseInt(value) || 0;

        const updatedProducts = products.map(product => {
            if (product.id === id) {
                const newSubtotal = (parseFloat(product.COST) * newQty).toFixed(2);
                return { ...product, QTY: newQty, SUB_TOTAL: newSubtotal };
            }
            return product;
        });

        setProducts(updatedProducts);
        computeTotalCost(updatedProducts);
    };
    console.log("products = ",products);
    const handleDeleteProduct = (id) => {
        const updatedProducts = products.filter(product => product.id !== id);
        setProducts(updatedProducts);
        computeTotalCost(updatedProducts);
    };

    const handleEditEstimate = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/edit_estimate`, {
                estimateId: location.state?.estimateNo,
                estimate_data_selected: products,
                totalCost: totalCost
            });
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Changed By PM',
                    text: response.data.message || 'Request was successful.',
                    confirmButtonText: 'OK',
                    preConfirm: () => {
                        navigate('/dashboard');
                    }
                });
                setLoading(false);
            } else {
                setLoading(true);
                Swal.fire({
                    icon: 'warning',
                    title: 'Unexpected Response',
                    text: `Received unexpected status code: ${response.status}`,
                });
            }
        } catch (err) {
            setLoading(true);
            if (err.response && err.response.data) {
                const { data } = err.response;
                const errorMessage = data.message || 'An unexpected error occurred.';

                let validationErrors = '';
                if (data.errors) {
                    for (const [field, messages] of Object.entries(data.errors)) {
                        validationErrors += `${field}: ${messages.join(', ')}\n`;
                    }
                }

                Swal.fire({
                    icon: 'error',
                    title: 'Validation Failed',
                    text: validationErrors || errorMessage,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.message || 'An unexpected error occurred.',
                });
            }
        }
    };

    const getTodayDate = () => {
        const now = new Date();
        const day = now.getDate().toString().padStart(2, '0');
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const year = now.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const getDate = getTodayDate();
    const json_data = {
        eventid: eventId,
        pmid: location.state?.pmId,
        estimate_type: estimateType,
        pax: location.state?.pax,
        estimate_parent: location.state?.estimate_parent,
        setup: location.state?.setup,
        status: "pending",
        client_total: totalCost,
        estimate_data_selected: products,
        date: getDate
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/estimates`, json_data);
            if (response.status === 200) {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Submitted To PM',
                    text: response.data.message || 'Request was successful.',
                    confirmButtonText: 'OK',
                    preConfirm: () => {
                        navigate('/dashboard');
                    }
                });
            } else {
                setLoading(true);
                Swal.fire({
                    icon: 'warning',
                    title: 'Unexpected Response',
                    text: `Received unexpected status code: ${response.status}`,
                });
            }
        } catch (err) {
            setLoading(true);
            if (err.response && err.response.data) {
                const { data } = err.response;
                const errorMessage = data.message || 'An unexpected error occurred.';

                let validationErrors = '';
                if (data.errors) {
                    for (const [field, messages] of Object.entries(data.errors)) {
                        validationErrors += `${field}: ${messages.join(', ')}\n`;
                    }
                }

                Swal.fire({
                    icon: 'error',
                    title: 'Validation Failed',
                    text: validationErrors || errorMessage,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.message || 'An unexpected error occurred.',
                });
            }
        }
    };
    const handleBackEstimates = () => {
        navigate(`/dashboard/showestimateseevent/${eventId}`,
            {state:
                {
                    eventId: eventId,
                    role:location.state?.role,
                    pmId: location.state?.pmId,
                    compId : location.state?.compId,
                    pmName : location.state?.pmName,
                    companyName : location.state?.companyName,
                    clientId : location.state?.clientId,
                    previousData : 1,
                    pax:location.state?.pax,
                    products:products,
                    setup: location.state?.setup,
                    selectedEstimate: location.state?.selectedEstimate,
                    estimateData: location.state?.estimateData,
                    paxData: location.state?.paxData,
                    estimateChildData: location.state?.estimateChildData,
                    estimateChildData1: location.state?.estimateChildData1,
                    fabrication: location.state?.fabrication,
                    estimateCommonData: location.state?.estimateCommonData,
                    estimateCommonData1: location.state?.estimateCommonData1,
                    fabriconStages: location.state?.fabriconStages,
                    fabriconPlatforms: location.state?.fabriconPlatforms,
                    estimateCommonData2: location.state?.estimateCommonData2,
                    subChildEstimate: location.state?.subChildEstimate,
                    selectAllFabrications: location.state?.selectAllFabrications,
                    estimate_parent: location.state?.estimate_parent
                }
            });
    }
    const handleShowStageRefDesigns = async(e) => {
        e.preventDefault();
        try{
            const params = { set_up : location.state?.setup,pax : location.state?.pax};
            const response =  await axios.get(`${process.env.REACT_APP_API_URL}/stage-reference-designs`,{
                params
            });
            if(response.status == '200'){
                setStageRefData(response.data.data || []);
                setShowStageRefModal(true);
            }
            else{
                setStageRefData([]);
                setShowStageRefModal(true);
            }
        }
        catch(e){
            setStageRefData([]);
            setShowStageRefModal(true);
        }
    }
    const handleCloseRefModal = () => {
        setShowStageRefModal(false);
    }
    return (
        <div className="product-cart-container">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 clearfix">
                        <h2 className="section-head">{location.state?.role == 1 ? `${estimateType} CART` : `EDIT ${estimateType} CART`}</h2>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>IMAGE</th>
                                    <th>ELEMENT</th>
                                    <th>SIZE</th>
                                    <th width="120px">COST</th>
                                    <th width="100px">QTY</th>
                                    <th width="100px">SUB TOTAL</th>
                                    {location.state?.role == 1 && <th>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {products.length > 0 ? (
                                    products.map(product => (
                                        <tr key={product.id} className="item-row">
                                            <td>
                                                <img src={product.image_url} alt={product.ELEMENT} style={{ width: '100px' }} />
                                            </td>
                                            <td>{product.ELEMENT}</td>
                                            <td>{product.SIZE}</td>
                                            <td>
                                                <span className="product-price">{product.COST}</span>
                                            </td>
                                            <td>
                                                <input
                                                    className="form-control item-qty"
                                                    type="number"
                                                    value={product.QTY}
                                                    onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                {product.SUB_TOTAL}
                                            </td>
                                            {location.state?.role == 1 && (
                                                <td>
                                                    <a
                                                        className="btn btn-sm remove-cart-item ps-3 pr-3"
                                                        href="#"
                                                        style={{ backgroundColor: 'red', color: 'white' }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteProduct(product.id);
                                                        }}
                                                    >
                                                        <span className="h5">X</span>
                                                    </a>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={location.state?.role == 1 ? 7 : 6} className="text-center">No products available</td>
                                    </tr>
                                )}
                                <tr>
                                    <td colSpan={location.state?.role == 1 ? 5 : 5} className="text-end"><strong>Total Cost:</strong></td>
                                    <td>
                                        <strong>{totalCost}</strong>
                                    </td>
                                    {location.state?.role == 1 && <td></td>}
                                </tr>
                            </tbody>
                        </table>
                        {location.state?.role == 1 ? (
                            <button className="btn btn-success btn-lg" onClick={handleSubmit}  disabled = {loading} >
                                 {loading ? ' Submitting For Proposal...' : 'Submit For Proposal'}
                            </button>
                        ) : (
                            <button className="btn btn-success btn-lg" onClick={handleEditEstimate} disabled = {loading}>
                               {loading ? ' Updating Estimates...' : 'Update Estimates'}
                            </button>
                        )}
                        <button className="btn btn-danger btn-lg ms-4" onClick = {handleBackEstimates}>BACK</button>
                        {location.state?.role == 1 && <a className="btn btn-primary btn-lg ms-4" onClick = { (e) => handleShowStageRefDesigns(e)} style={{color:'white'}}> SHOW STALL REFERENCE DESIGNS </a>}
                        
                    </div>
                </div>
            </div>
            <StageReferenceModal 
                refData={stageRefData}
                show={showStageRefModal}
                handleClose={handleCloseRefModal}
            />
        </div>
        
    );
};

const NextEstimateEvent = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <>
            <div className="u-body u-xl-mode" data-lang="en">
                <Header imagTag='../../' role = {1} active = {{estimates:1}} />
            </div>
            <HeaderMenu />
            
        </>
    );
}

export default NextEstimateEvent;
