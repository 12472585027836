import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import './cart.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import Header from './Header';
const HeaderMenu = () => {
    // const [userRole,setUserRole] = useState(0);
    const [products, setProducts] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    // const [checkOutFlag,setCheckOutFlag] = useState(false);
    const { eventId } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (location.state && location.state.estimate_data_selected) {
            const subChildEstimate = location.state.estimate_data_selected;
            // Initialize SUB_TOTAL for each product
            const initializedProducts = subChildEstimate.map(product => ({
                ...product,
                SUB_TOTAL: (parseFloat(product.COST) * product.QTY).toFixed(2)
            }));

            setProducts(initializedProducts);
            computeTotalCost(initializedProducts);
        }
        // setUserRole(location.state?.role || 1);
    }, [location.state]);
    const role = location.state?.role;

    const estimateId = location.state?.estimateNo;
    const estimate_type = location.state?.estimateType;
    const computeTotalCost = (products) => {
        const total = products.reduce((acc, product) => acc + (parseFloat(product.COST) * product.QTY), 0);
        setTotalCost(total.toFixed(2));
    };
    const handleQuantityChange = (id, value) => {
        const updatedProducts = products.map(product => {
            if (product.id === id) {
                const newQty = parseInt(value) || 0;
                const newSubtotal = (parseFloat(product.COST) * newQty).toFixed(2);
                return { ...product, QTY: newQty, SUB_TOTAL: newSubtotal }; // Assuming you have a SUB_TOTAL field
            }
            return product;
        });
        setProducts(updatedProducts);
        computeTotalCost(updatedProducts);
    };
    const handleDeleteProduct = async (id) => {
        try {
            const updatedProducts = products.filter(product => product.id != id);
            setProducts(updatedProducts);
            computeTotalCost(updatedProducts);
            let url = "";
            url = estimate_type == 'INCLINIC' ? `${process.env.REACT_APP_API_URL}/printel-cart/remove` : `${process.env.REACT_APP_API_URL}/cart/remove`;
            await axios.post(url, {
                id: id
            });
        }
        catch (e) {
        }
    };
    const handleEditEstimate = async () => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/edit_estimate`,
                {
                    estimateId: estimateId,
                    estimate_data_selected: products,
                    totalCost: totalCost

                });

            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Changed By PM',
                    text: response.data.message || 'Request was successful.',
                    confirmButtonText: 'OK',
                    preConfirm: () => {
                        navigate('/dashboard');
                    }
                });

            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Unexpected Response',
                    text: `Received unexpected status code: ${response.status}`,
                });
            }
        } catch (err) {
            if (err.response && err.response.data) {
                const { data } = err.response;
                const errorMessage = data.message || 'An unexpected error occurred.';

                let validationErrors = '';
                if (data.errors) {
                    for (const [field, messages] of Object.entries(data.errors)) {
                        validationErrors += `${field}: ${messages.join(', ')}\n`;
                    }
                }

                Swal.fire({
                    icon: 'error',
                    title: 'Validation Failed',
                    text: validationErrors || errorMessage,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.message || 'An unexpected error occurred.',
                });
            }
        }

        // setProducts(updatedProducts);
        // computeTotalCost(updatedProducts);
    };
    const getTodayDate = () => {
        const now = new Date();
        const day = now.getDate().toString().padStart(2, '0');
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const year = now.getFullYear();
        return `${year}-${month}-${day}`;
    };
    const getDate = getTodayDate();
    const json_data = {
        eventid: eventId,
        pmid: location.state?.pmId,
        estimate_type: location.state?.estimateType,
        pax: location.state?.pax,
        estimate_parent: location.state?.estimate_parent,
        setup: location.state?.setup,
        status: "pending",
        client_total: totalCost,
        estimate_data_selected: products,
        date: getDate
    };
    const checkOut = async () => {
        console.log("cart");
        try {
            let urlCheckout = ""
            urlCheckout = estimate_type == 'INCLINIC' ? `${process.env.REACT_APP_API_URL}/printel-cart/checkout` : `${process.env.REACT_APP_API_URL}/cart/checkout`
            await axios.post(urlCheckout,
                {
                    event_id: eventId,
                    user_id: location.state?.clientId
                }
            );
        }
        catch {

        }
    }

    const handleSubmit = async () => {
        try {
            setLoading(true);

            // Show SweetAlert2 with input for remark
            const { value: remark } = await Swal.fire({
                title: 'Add a Remark',
                input: 'textarea',
                inputLabel: 'Remark',
                inputPlaceholder: 'Type your remark here...',
                inputAttributes: {
                    'aria-label': 'Type your remark here'
                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
            });

            
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/estimates`, {...json_data,remarks:remark});

                if (response.status === 200) {
                    // Handle successful response
                    Swal.fire({
                        icon: 'success',
                        title: 'Submitted To PM',
                        text: response.data.message || 'Request was successful.',
                        confirmButtonText: 'OK',
                        preConfirm: async () => {
                            await checkOut();
                            navigate(`/dashboard/showestimates/${eventId}`, {
                                state: {
                                    eventId,
                                    role: location.state?.role,
                                    pmId: location.state?.pmId,
                                    compId: location.state?.compId,
                                    pmName: location.state?.pmName,
                                    companyName: location.state?.companyName,
                                    clientId: location.state?.clientId
                                }
                            });
                        }
                    });
                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Unexpected Response',
                        text: `Received unexpected status code: ${response.status}`,
                    });
                }
            
            
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong. Please try again later.'
            });
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="product-cart-container">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 clearfix">
                        <h2 className="section-head">{role == 1 ? `${estimate_type} CART` : 'EDIT EXHIBITION CART'}</h2>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>IMAGE</th>
                                    <th>ELEMENT</th>
                                    <th>SIZE</th>
                                    <th width="120px">COST</th>
                                    <th width="100px">QTY</th>
                                    <th width="100px">SUB TOTAL</th>
                                    {role == 1 && <th>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {products.length > 0 ? (
                                    products.map(product => (
                                        <tr key={product.id} className="item-row">
                                            <td>
                                                <img src={product.image_url} alt={product.ELEMENT} style={{ width: '100px' }} />
                                            </td>
                                            <td>{product.ELEMENT}</td>
                                            <td>{product.SIZE}</td>
                                            <td>
                                                <span className="product-price">{product.COST}</span>
                                            </td>
                                            <td>
                                                <input
                                                    className="form-control item-qty"
                                                    type="number"
                                                    value={product.QTY}
                                                    onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value) || 0)}
                                                />
                                            </td>
                                            <td>
                                                {(parseFloat(product.COST) * product.QTY).toFixed(2)}
                                            </td>
                                            {role == 1 && (
                                                <td>
                                                    <a
                                                        className="btn btn-sm remove-cart-item ps-3 pr-3"
                                                        href="#"
                                                        style={{ backgroundColor: 'red', color: 'white' }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleDeleteProduct(product.id);
                                                        }}
                                                    >
                                                        <span className="h5">X</span>
                                                    </a>
                                                </td>
                                            )}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={role == 1 ? 7 : 6} className="text-center">No products available</td>
                                    </tr>
                                )}
                                <tr>
                                    <td colSpan={role == 1 ? 5 : 5} className="text-end"><strong>Total Cost:</strong></td>
                                    <td>
                                        <strong>{totalCost}</strong>
                                    </td>
                                    {role == 1 && <td></td>}
                                </tr>
                            </tbody>
                        </table>
                        {products.length > 0 ?
                            (
                                role == 1 ? (<button className="btn btn-success btn-lg me-3" onClick={handleSubmit} disabled={loading}>
                                    {loading ? ' Submitting For Proposal...' : 'Submit For Proposal'}
                                </button>) :
                                    (<button className="btn btn-success btn-lg me-3" onClick={handleEditEstimate}>
                                        {loading ? ' Updating Estimates...' : 'Update Estimates'}
                                    </button>)
                            ) : ('')}
                    </div>

                </div>
            </div>
        </div>
    );
};
const ExhibitionCartEvent = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <>
            <div className="u-body u-xl-mode" data-lang="en">
                <Header imagTag="../../" role={1} active={{ estimates: 1 }} />
            </div>
            <HeaderMenu />
        </>
    );
}
export default ExhibitionCartEvent;
