import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RingLoader } from 'react-spinners';
import Header from './Header';
// Fullscreen Image Modal Component
const FullscreenContentModal = ({ show, contentUrl, onClose, type }) => {
    const videoRef = useRef(null);
    useEffect(() => {
        // videoRef.current.pause();
        if (type === 'video' && videoRef.current && show) {
            // Play the video when modal opens
            videoRef.current.pause();
            videoRef.current.play();
        }
    }, [show, type]);
    const renderContent = () => {
        switch (type) {
            case 'image':
                return <img src={contentUrl} alt="Full View" className="img-fluid" />;
            case 'video':
                return (
                    <video ref={videoRef} controls className="img-fluid" style={{ maxWidth: '100%' }}>
                        <source src={contentUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                );
            case 'pdf':
                return (
                    <iframe
                        src={contentUrl}
                        style={{ width: '100%', height: '80vh' }}
                        title="PDF Document"
                    ></iframe>
                );
            case 'csv':
                return (
                    <iframe
                        src={`data:text/csv;charset=utf-8,${encodeURI(contentUrl)}`}
                        style={{ width: '100%', height: '100vh' }}
                        title="CSV Document"
                    ></iframe>
                );
            case 'xlsx':
                return (
                    <iframe
                        src={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${contentUrl}`}
                        style={{ width: '100%', height: '100vh' }}
                        title="Excel Document"
                    ></iframe>
                );
            default:
                return <p>Unsupported file type</p>;
        }
    };

    return (
        <Modal show={show} onHide={onClose} size="lg" fullscreen="sm-down">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="d-flex justify-content-center align-items-center">
                {renderContent()}
            </Modal.Body>
        </Modal>
    );
};

function ShowPostEvent() {
    const location = useLocation() || null;
    const { eventId } = useParams();
    const navigate = useNavigate();
    const [selectedContent, setSelectedContent] = useState(null);
    const [contentType, setContentType] = useState('image');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [others, setOthers] = useState([]);
    const [feedbackUrl, setFeedbackUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('images'); // Default to 'images'

    useEffect(() => {
        const fetchData = async () => {
           

            setLoading(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/postEventImg`, { event_id: eventId });

                // Assuming the response includes all data at once
                setImages(response.data.images || []);
                setVideos(response.data.videos || []);
                setOthers(response.data.others || []);
                setFeedbackUrl(response.data.feedback_url || '');
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
                // Set loading state based on the active tab after the data is loaded
            }
        };

        fetchData();
    }, [eventId, activeTab]);


    const handleLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/login');
    };
    const determineContentType = (url) => {
        if (url.endsWith('.mp4')) return 'video';
        if (url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg')) return 'image';
        if (url.endsWith('.pdf')) return 'pdf';
        if (url.endsWith('.csv')) return 'csv';
        if (url.endsWith('.xlsx')) return 'xlsx';
        return 'other';
    };
    const handleTabClick = (tab) => {
        if (tab === 'images') {
            if (images.length === 0 && !loading) {
                setLoading(false);
            }
        } else if (tab === 'videos') {
            if (videos.length === 0 && !loading) {
                setLoading(false);
            }
        } else if (tab === 'others') {
            if (others.length === 0 && !loading) {
                setLoading(false);
            }
        }
        setActiveTab(tab);
    };
    const handleContentClick = (contentUrl) => {
        const type = determineContentType(contentUrl);
        setSelectedContent(contentUrl);
        setContentType(type);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedContent(null);
    };

    const pmName = location.state?.pmName || 'Unknown PM';
    const companyName = location.state?.companyName || 'Unknown Company';
    const role  = location.state?.role || 1;
    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{height: '100vh' }}>
                <RingLoader color="#123abc" />
            </div>
        );
    }

    return (
        <>
            <div className="u-body u-xl-mode" data-lang="en" style = {{position : "relative",zIndex:"10000000"}} >
                <Header imagTag = "../../" role = {role} active = {{post_event:1}}/>
                <section className="u-align-center u-clearfix u-section-1" id="sec-0e20">
                    <div className="data-layout-selected u-clearfix u-expanded-width u-layout-wrap u-palette-5-light-3 u-layout-wrap-1">
                        <div className="u-gutter-0 u-layout">
                            <div className="u-layout-row">
                                <div className="u-align-center u-container-style u-layout-cell u-size-30 u-layout-cell-1">
                                    <div className="u-container-layout u-container-layout-1">
                                        <h4 className="u-align-center u-text u-text-1">Company : {companyName}</h4>
                                    </div>
                                </div>
                                <div className="u-align-center u-container-style u-layout-cell u-size-30 u-layout-cell-1">
                                    <div className="u-container-layout u-container-layout-1">
                                        <h4 className="u-align-center u-text u-text-1">PM : {pmName}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-3">
                        <div className="nav">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active" aria-current="page">
                                        FeedBack Url >> <a target="_blank" href={feedbackUrl}>{feedbackUrl}</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <ul className="nav nav-tabs nav-fill mb-3" id="ex1" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className={`nav-link ${activeTab === 'images' ? 'active' : ''}`}
                                    id="ex2-tab-1"
                                    data-bs-toggle="tab"
                                    href="#ex2-tabs-1"
                                    role="tab"
                                    aria-controls="ex2-tabs-1"
                                    aria-selected={activeTab === 'images'}
                                    onClick={() => handleTabClick('images')}
                                >Images</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className={`nav-link ${activeTab === 'videos' ? 'active' : ''}`}
                                    id="ex2-tab-2"
                                    data-bs-toggle="tab"
                                    href="#ex2-tabs-2"
                                    role="tab"
                                    aria-controls="ex2-tabs-2"
                                    aria-selected={activeTab === 'videos'}
                                    onClick={() => handleTabClick('videos')}
                                >Videos</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className={`nav-link ${activeTab === 'others' ? 'active' : ''}`}
                                    id="ex2-tab-3"
                                    data-bs-toggle="tab"
                                    href="#ex2-tabs-3"
                                    role="tab"
                                    aria-controls="ex2-tabs-3"
                                    aria-selected={activeTab === 'others'}
                                    onClick={() => handleTabClick('others')}
                                >Others</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="ex2-content">
                            <div
                                className={`tab-pane fade ${activeTab === 'images' ? 'show active' : ''}`}
                                id="ex2-tabs-1"
                                role="tabpanel"
                                aria-labelledby="ex2-tab-1"
                            >
                                {
                                    images.length > 0 ? (
                                        <div className="row" id="eventManagement">
                                            {images.map((imageUrl, index) => (
                                                <div
                                                    key={index}
                                                    className="col-md-4"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleContentClick(imageUrl)}
                                                >
                                                    <LazyLoadImage
                                                        src={imageUrl}
                                                        alt={`Image ${index + 1}`}
                                                        className="img-thumbnail"
                                                        effect="blur"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p>No images available</p>
                                    )
                                }
                            </div>
                            <div
                                className={`tab-pane fade ${activeTab === 'videos' ? 'show active' : ''}`}
                                id="ex2-tabs-2"
                                role="tabpanel"
                                aria-labelledby="ex2-tab-2"
                            >
                                {
                                    videos.length > 0 ? (
                                        videos.map((videoUrl, index) => (
                                            <div
                                                key={index}
                                                className="col-12"
                                            >
                                                <video
                                                    src={videoUrl}
                                                    alt={`Video ${index + 1}`}
                                                    className="img-thumbnail"
                                                    muted
                                                    controls
                                                    style={{ height: '100vh' }}
                                                ></video>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No videos available</p>
                                    )
                                }
                            </div>
                            <div
                                className={`tab-pane fade ${activeTab === 'others' ? 'show active' : ''}`}
                                id="ex2-tabs-3"
                                role="tabpanel"
                                aria-labelledby="ex2-tab-3"
                            >
                                {
                                    others.length > 0 ? (
                                        <div className="row">
                                            {others.map((fileUrl, index) => (
                                                <div
                                                    key={index}
                                                    className="col-12"
                                                >
                                                    {fileUrl.endsWith('.pdf') ? (
                                                        <iframe src={fileUrl} style={{ height: "100vh", width: '80vw' }} title="PDF Document"></iframe>
                                                    ) : fileUrl.endsWith('.csv') ? (
                                                        <iframe src={`data:text/csv;charset=utf-8,${encodeURI(fileUrl)}`} style={{ width: '100%', height: '100vh' }} title="CSV Document"></iframe>
                                                    ) : (
                                                        <iframe src={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${fileUrl}`} style={{ width: '100%', height: '100vh' }} title="Excel Document"></iframe>
                                                    )}
                                                </div>
                                    ))}
                                        </div>
                                    ) : (
                                        <p>No other files available</p>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </section>
            </div>
            <FullscreenContentModal
                show={isModalOpen}
                contentUrl={selectedContent}
                onClose={handleCloseModal}
                type={contentType}
            />
        </>
    );
}

export default ShowPostEvent;